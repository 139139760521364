import { Link } from 'gatsby';
import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import WonderbookLogo from '../svg/Wonderbook-Logo.svg';

const NotFoundPage = () => (
    <Layout>
        <SEO title="404: Not found" />
        <div className="container txt-center pt-12">
            <Link to="/">
                <WonderbookLogo className="txt-primary mb-24" />
            </Link>
            <h1 className="h2">404: Not Found</h1>
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </div>
    </Layout>
);

export default NotFoundPage;
